import {
  cast,
  flow,
  getParent,
  Instance,
  IStateTreeNode,
  toGenerator,
  types,
  destroy,
} from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import newId from "../utils/new-id"
import { Sync, SyncStatus } from "../models/sync"
import { MissingTimelineAssetPostParams, TimelineApi } from "../services/api/timeline-api"
import {
  TimelineModel,
  Timeline,
  VideoProperties,
  TimelineCover,
  Clip,
  ClipModel,
  AssetType,
  VideoAssetOptions,
  AudioAssetOptions,
  TextAssetOptions,
} from "../models/timeline"
import { difference, flatten, uniqBy } from "lodash-es"
import logger from "../logging/logger"
import { withTimelineAssetStore } from "./timeline-asset-store"
import { toJS } from "mobx"
import { CANVAS_ASPECT_RATIO } from "../utils/constants"
import { format } from "date-fns"
import { color } from "../theme/shared-colors"
import { setUndoManager, undoManager } from "./undo-manager"
import { DATE_TIME_FORMAT } from "../utils"
import { withAssetStore } from "./asset-store"
import { AssetAddParams, AssetModel } from "../models/asset"

export const ReorderingStateModel = types.model("ReorderingState").props({
  /**
   * Are we currently dragging to reorder?
   */
  isReordering: types.optional(types.boolean, false),
  /**
   * The clip being dragged
   */
  clip: types.maybe(types.safeReference(ClipModel)),
  /**
   * The current index that the dragged clip would swap to if let go
   */
  draggedIndex: types.maybe(types.number),
  /**
   * How far we need to offset the items so they appear under your finger
   */
  offsetLeft: types.maybe(types.number),
  /**
   * How far we need to offset the item being currently dragged
   */
  offsetDraggable: types.maybe(types.number),
})
export type ReorderingState = Instance<typeof ReorderingStateModel>

export enum TrimSide {
  Left = "Left",
  Right = "Right",
}

export const TrimmingStateModel = types.model("TrimmingState").props({
  /**
   * Are we currently trimming a clip
   */
  isTrimming: types.optional(types.boolean, false),
  /**
   * The clip being trimmed
   */
  clip: types.maybe(types.safeReference(ClipModel)),
  /**
   * The clip being trimmed
   */
  trimSide: types.maybe(types.enumeration([TrimSide.Left, TrimSide.Right])),
  /**
   * If we are trimming from the front, we need to offset,
   * all the clip after it backwards by this amount.
   */
  trimStartOffset: types.maybe(types.number),
})

export const TimelineStoreModel = types
  .model("TimelineStore")
  .props({
    timelines: types.map(TimelineModel),

    selectedClip: types.maybe(types.safeReference(ClipModel)),
    seek: types.optional(types.number, 0),
    isPlaying: types.optional(types.boolean, true),

    reorderingState: types.optional(ReorderingStateModel, { isReordering: false }),
    trimmingState: types.optional(TrimmingStateModel, { isTrimming: false }),

    canvasExpanded: types.optional(types.boolean, false),
    // 100px = 1s
    timelineZoom: types.optional(types.number, 40),
    customCoverAsset: types.maybe(AssetModel),
    // Undo / Redo
  })
  .extend(withEnvironment)
  .extend(withTimelineAssetStore)
  .extend(withAssetStore)
  .actions((self) => {
    setUndoManager(self)
    return {}
  })
  .views((self) => ({
    getTimelinePath(timelineId: string) {
      return `${self.environment.fileSystem.documentDirectory}timeline/${timelineId}`
    },
  }))
  .actions((self) => ({
    removeLocalTimeline: flow(function* (timelineId: string) {
      self.environment.fileSystem.deleteAsync(self.getTimelinePath(timelineId))
      const timeline = self.timelines.get(timelineId)
      if (timeline) {
        destroy(timeline)
      }
    }),
  }))
  .actions((self) => ({
    setCanvasExpanded(isExpanded: false) {
      undoManager.withoutUndo(() => {
        self.canvasExpanded = isExpanded
      })
    },
    toggleCanvasExpanded() {
      undoManager.withoutUndo(() => {
        self.canvasExpanded = !self.canvasExpanded
      })
    },
    updateLocalTimelineFromServer: flow(function* (timeline: Timeline) {
      const existingTimeline = self.timelines.get(timeline.id)
      // only replace the local timelines if we have new data
      if (!existingTimeline) {
        const assetSyncs: { [assetId: string]: Sync } = {}

        // Server timeline needs to have assets converted to references.
        timeline.tracks.forEach((track, index) => {
          track.clips.forEach((clip) => {
            self.timelineAssetStore.addExistingAssetFromServer(clip.asset, timeline)

            // Assume all assets are new; they'll get updated properly when saveAssets() is called again.
            assetSyncs[clip.asset.id] = cast<Sync>({
              status: SyncStatus.New,
            })

            clip.asset = clip.asset.id as any // Store as reference

            // TODO - Below is an annoying hack to get these values from the server
            // back onto the clip properly.

            // Duration is computed right now for videos,
            // a decent fix would be to remove trimEnd from the asset options, and use
            // duration always on the clip + trim start. Then we figure out when it ends
            // by looking at trim start, the duration of the asset, and the duration of
            // clip.
            if (clip.duration) {
              clip._duration = clip.duration
            }

            // Start position is annoying because on the main track, it is a computed value,
            // but on all the overlay tracks it is set explicitly. It would be nice if the
            // mobx computed getter could just check if it was set explicitly, but you can't
            // have a computed with the same name. A possible solution to fix this hack for
            // start position could be to always store the start position for the main track,
            // and just compute it and set it on the object explicitly whenever there is a change
            // on the main track that would shift it. e.g. trimming the first clip changes the start
            // position of all subsequent clips on the main tracks
            const isMainTrack = index === 0
            if (!isMainTrack) {
              if (clip.startPosition) {
                clip._startPosition = clip.startPosition
              } else if (clip.startPosition === 0) {
                clip._startPosition = 0
              }
            }
          })
        })

        self.timelines.put({
          ...timeline,
          sync: cast<Sync>({ status: SyncStatus.Synced }),
          assetSyncs: assetSyncs as any,
        })

        const storeTimeline = self.timelines.get(timeline.id)
        if (storeTimeline) {
          yield self.timelineAssetStore.refreshContentUris(storeTimeline)
        }
      } else {
        yield self.timelineAssetStore.refreshContentUris(existingTimeline)
        if (existingTimeline.sync.status === SyncStatus.Synced) {
          if (existingTimeline.cover) {
            existingTimeline.cover.thumbnailAssetId = timeline.cover?.thumbnailAssetId
          }
        }
      }
    }),
    // Set the timeline cover based on a current point in time
    // and generate the image
    setCover: function (timeline: Timeline, cover: TimelineCover) {
      timeline.cover = cover
      timeline.markAsDirty()
    },
    uploadCoverThumbnailAsset: flow(function* (params: AssetAddParams) {
      const asset = self.assetStore.createAsset(params)
      yield self.assetStore.uploadMobileAsset(asset)
      self.customCoverAsset = asset
    }),
    resetCoverThumbnail: function () {
      self.customCoverAsset = undefined
    },
    setName: function (timeline: Timeline, name: string) {
      timeline.name = name
    },
    ensureDirExists: flow(function* (timeline: Timeline) {
      const timelineDir = self.getTimelinePath(timeline.id)
      const exists = yield* toGenerator(self.environment.fileSystem.existsAsync(timelineDir))
      if (!exists) {
        logger.log("Timeline directory doesn't exist, creating...")
        yield self.environment.fileSystem.makeDirectoryAsync(timelineDir)
      }
    }),
    setSelectedClip(clipId: string | undefined) {
      undoManager.withoutUndo(() => {
        self.selectedClip = clipId as any
      })
    },
    setTimelineZoom(timelineZoom: number) {
      undoManager.withoutUndo(() => {
        self.timelineZoom = timelineZoom
      })
    },
    setSeek(seek: number) {
      undoManager.withoutUndo(() => {
        self.seek = Math.max(0, seek)
      })
    },
    reorderClips(timelineId: string, originClipId: string, destinationClipId: string) {
      const currentTimeline = self.timelines.get(timelineId)
      if (!currentTimeline?.mainTrack) {
        return
      }
      const clips = currentTimeline.mainTrack.clips.toJSON()
      const indexToMove = clips.findIndex((c) => c.id === originClipId) || 0
      const indexToMoveTo = clips.findIndex((c) => c.id === destinationClipId) || 0
      if (indexToMove >= 0 && indexToMoveTo >= 0) {
        // Move the clip to the new index
        const clipToMove = clips.splice(indexToMove, 1)
        clips.splice(indexToMoveTo, 0, clipToMove[0])
        // Need to replace the whole clips array rather than move in place, because a move
        // in place changes one at a time which causes reference problems since you have
        // duplicate ids for a moment in time
        currentTimeline.mainTrack.clips.replace(clips)
      }
      currentTimeline.markAsDirty()
    },
    setIsPlaying(isPlaying: boolean) {
      undoManager.withoutUndo(() => {
        self.isPlaying = isPlaying
      })
    },
    setTrimmingState(
      isTrimming: boolean,
      trimSide?: TrimSide,
      clip?: Clip,
      trimStartOffset?: number,
    ) {
      undoManager.withoutUndo(() => {
        self.trimmingState = {
          trimSide,
          isTrimming,
          clip,
          trimStartOffset,
        }
      })
    },
  }))
  .actions((self) => {
    let seekInterval: ReturnType<typeof setInterval>
    return {
      startPlaying(timelineId: string) {
        const currentTimeline = self.timelines.get(timelineId)
        self.setIsPlaying(true)
        // Update the seek based on the change in time
        // Use timestamps for increased accuracy
        const initSeek = self.seek
        const initStart = Date.now()
        seekInterval = setInterval(
          () => {
            if (!currentTimeline?.mainTrack) {
              return
            }
            const nextSeek = initSeek + (Date.now() - initStart) / 1000
            if (nextSeek > (currentTimeline.mainTrack.trackLength || 0)) {
              clearInterval(seekInterval)
              self.setIsPlaying(false)

              // setting seek to be at the end of the main track.
              // the 0.001 keeps the last (close to last) frame of the video on canvas
              // instead of showing no video
              self.setSeek(currentTimeline.mainTrack.trackLength - 0.001)
            } else {
              self.setSeek(nextSeek)
            }
          },
          self.environment.platform === "android" ? 200 : 30,
          // TODO-GS in my testing android needed a slower interval to still be usable
          // (it seems that updating this too fast can make the JS thread lock and not handle clicks)
          // could be device specfic but I don't have enough devices with the same OS
          // and differing performance specs to test on. Also from my research, this could
          // run alot faster when we actually do a release build.
        )
      },
      stopPlaying() {
        self.setIsPlaying(false)
        clearInterval(seekInterval)
      },
      setReorderingState(state: ReorderingState) {
        undoManager.withoutUndo(() => {
          self.reorderingState = state
        })
      },
      reset() {
        self.setTimelineZoom(40)
        self.setTrimmingState(false)
      },
      getTimelineThumbnail(timeline: Timeline) {
        const firstClip =
          (timeline?.mainTrack?.clips?.length || 0) > 0 ? timeline?.mainTrack?.clips[0] : undefined

        if (firstClip?.asset?.contentUri) {
          if (firstClip.asset.type === AssetType.Video) {
            return self.timelineAssetStore.getAssetThumbnail(
              firstClip?.asset,
              (firstClip.assetOptions as VideoAssetOptions).trimStart * 1000,
            )
          } else {
            return self.timelineAssetStore.getAssetThumbnail(firstClip?.asset, 0)
          }
        }
        return undefined
      },
      cloneTimelineFromPitch: flow(function* (pitchId: string) {
        const api = new TimelineApi(self.environment.api)
        const result = yield* toGenerator(api.getTimelineByPitch(pitchId))
        // get the old timeline path, then create a new id for the timeline
        const oldTimelinePath = self.getTimelinePath(result.timeline.id)
        result.timeline.id = newId().id
        result.timeline.name = format(new Date(), "MMM d, yyy h:mm a")
        result.timeline.tracks.forEach((track) => {
          track.clips.forEach((clip) => {
            const newClipId = newId().id
            // the timeline JSON has a guid clip which gets converted into a safeReference
            if (result.timeline.cover && result.timeline.cover?.clip === (clip.id as any)) {
              result.timeline.cover.clip = newClipId as any
            }
            clip.id = newClipId
          })
        })
        const newTimelinePath = self.getTimelinePath(result.timeline.id)

        // copy all of the local assets to the new timeline
        yield self.ensureDirExists(result.timeline)
        yield self.environment.fileSystem.copyAsync({ from: oldTimelinePath, to: newTimelinePath })

        // create all the asset syncs
        yield self.updateLocalTimelineFromServer(result.timeline)

        // but force the timeline to be new
        const timeline = self.timelines.get(result.timeline.id)
        timeline?.sync.setStatus(SyncStatus.New)

        return timeline
      }),
      createNewTimeline() {
        return undoManager.withoutUndo(() => {
          const timeline = TimelineModel.create({
            id: newId().id,
            name: format(new Date(), DATE_TIME_FORMAT),
          })
          self.timelines.put(timeline)
          return timeline
        })
      },
      deleteTimeline: flow(function* (timeline: Timeline) {
        if (timeline.sync.status !== SyncStatus.New) {
          const api = new TimelineApi(self.environment.api)
          yield api.deleteTimeline(timeline.id)
        }

        yield self.removeLocalTimeline(timeline.id)
      }),
      resetStreamableStatus: flow(function* (timeline: Timeline) {
        const api = new TimelineApi(self.environment.api)
        yield api.resetStreamableStatus(timeline.id)
      }),

      addNewVideoAsset: flow(function* (
        timelineId: string,
        videoUri: string,
        videoProperties: VideoProperties,
        {
          recordedInApp,
          scriptId,
        }: { recordedInApp?: boolean; scriptId?: string } | undefined = {},
      ) {
        const timeline = self.timelines.get(timelineId)

        if (!videoProperties.duration) {
          throw new Error("Videos must have a duration")
        }

        if (!timeline) {
          throw new Error("Timeline not found")
        }

        yield self.ensureDirExists(timeline)

        const asset = yield* toGenerator(
          self.timelineAssetStore.createVideoAsset(timeline, videoUri, videoProperties),
        )

        timeline.mainTrack?.clips.push(
          ClipModel.create({
            ...newId(),
            asset: asset.id,
            assetOptions: {
              type: AssetType.Video,
              trimStart: 0,
              trimEnd: 0,
              recordedInApp,
              scriptId,
            },
          }),
        )

        // If we don't have a sync for this asset yet,
        if (!timeline.assetSyncs.has(asset.id)) {
          timeline.assetSyncs.set(asset.id, {})
        }

        timeline.markAsDirty()
      }),

      addAudioClip: flow(function* (
        timeline: Timeline,
        audioUri: string,
        duration: number,
        fileName: string,
        extension: string,
        seek?: number,
        trimStart = 0,
        trimEnd = 0,
      ) {
        yield self.ensureDirExists(timeline)

        const asset = yield* toGenerator(
          self.timelineAssetStore.createAudioAsset(
            timeline,
            audioUri,
            duration,
            extension,
            fileName,
          ),
        )

        const newAudioClip = ClipModel.create({
          ...newId(),
          asset: asset.id,
          assetOptions: { type: AssetType.Audio, trimEnd, trimStart, volume: 1 },
          _startPosition: seek === undefined ? self.seek : seek,
        })

        if (!timeline.audioTracks.length) {
          timeline.tracks.push({ clips: [] })
          timeline.tracks[timeline.tracks.length - 1].clips.push(newAudioClip)
        } else {
          const firstAudioTrack = timeline.audioTracks[0]
          firstAudioTrack.clips.push(newAudioClip)
          if (!firstAudioTrack.hasRoomForClip(newAudioClip)) {
            timeline.moveClipToNextAvailableTrack(newAudioClip)
          }
        }

        // If we don't have a sync for this asset yet,
        if (!timeline.assetSyncs.has(asset.id)) {
          timeline.assetSyncs.set(asset.id, {})
        }

        timeline.markAsDirty()

        return newAudioClip
      }),
      updateTextOverlay: flow(function* (
        timeline: Timeline,
        clip: Clip,
        contentUri: string,
        width: number,
        height: number,
      ) {
        const uri = !contentUri?.startsWith("file://") ? "file://" + contentUri : contentUri
        const updatedAsset = yield* toGenerator(
          undoManager.withoutUndoFlow(function* () {
            return yield* toGenerator(
              self.timelineAssetStore.createTextAsset(timeline, uri, width, height),
            )
          })(),
        )
        undoManager.withoutUndo(() => {
          clip.asset = updatedAsset.id as any
          if (!timeline.assetSyncs.has(updatedAsset.id)) {
            timeline.assetSyncs.set(updatedAsset.id, {})
          }
        })
        timeline.markAsDirty()
      }),
      addTextOverlay: function (
        timeline: Timeline,
        text?: string,
        fontColor?: string,
        fontBackgroundColor?: string,
      ) {
        const asset = self.timelineAssetStore.createBlankTextAsset()
        const newTextClip = ClipModel.create({
          ...newId(),
          asset: asset.id,
          assetOptions: {
            type: AssetType.Text,
            text,
            color: fontColor || color.text,
            backgroundColor: fontBackgroundColor || "rgba(0,0,0,0.5)",
          },
          left: 1,
          top: 15,
          width: 0,
          height: 0,
          _startPosition: self.seek,
          _duration: 5,
        })

        if (!timeline.overlayTracks.length) {
          timeline.tracks.push({ clips: [] })
          timeline.tracks[timeline.tracks.length - 1].clips.push(newTextClip)
        } else {
          const firstOverlayTrack = timeline.overlayTracks[0]
          firstOverlayTrack.clips.push(newTextClip)
          if (!firstOverlayTrack.hasRoomForClip(newTextClip)) {
            timeline.moveClipToNextAvailableTrack(newTextClip)
          }
        }
        timeline.markAsDirty()
        return newTextClip.id
      },
      addImageOverlay: flow(function* (
        timeline: Timeline,
        imageUri: string,
        fileName: string,
        extension: string,
        width: number,
        height: number,
      ) {
        yield self.ensureDirExists(timeline)

        const asset = yield* toGenerator(
          self.timelineAssetStore.createImageAsset(
            timeline,
            imageUri,
            extension,
            fileName,
            width,
            height,
          ),
        )

        const newImageClip = ClipModel.create({
          ...newId(),
          asset: asset.id,
          assetOptions: { type: AssetType.Image },
          left: 25,
          top: 65,
          width: 50,
          height: (50 / (width / height)) * CANVAS_ASPECT_RATIO,
          _startPosition: self.seek,
          _duration: 5,
        })

        if (!timeline.overlayTracks.length) {
          timeline.tracks.push({ clips: [] })
          timeline.tracks[timeline.tracks.length - 1].clips.push(newImageClip)
        } else {
          const firstOverlayTrack = timeline.overlayTracks[0]
          firstOverlayTrack.clips.push(newImageClip)
          if (!firstOverlayTrack.hasRoomForClip(newImageClip)) {
            timeline.moveClipToNextAvailableTrack(newImageClip)
          }
        }

        // If we don't have a sync for this asset yet,
        if (!timeline.assetSyncs.has(asset.id)) {
          timeline.assetSyncs.set(asset.id, {})
        }

        timeline.markAsDirty()
      }),
      splitClip: flow(function* (timeline: Timeline, originalClip: Clip, seek: number) {
        const { asset } = originalClip

        const isVideo = asset.type === AssetType.Video
        const isAudio = asset.type === AssetType.Audio
        const isImage = asset.type === AssetType.Image
        const isText = asset.type === AssetType.Text

        const assetPosition =
          isVideo || isAudio
            ? seek -
              originalClip.startPosition +
              (originalClip.assetOptions as VideoAssetOptions).trimStart
            : seek - originalClip.startPosition

        const newClipId = newId().id
        const isMainTrack = timeline.isClipOnMainTrack(originalClip)

        // If we split and the cover is in the second half now, the old cover
        // will be removed and we need to add a new one with the new clip
        // that was created
        const newCover =
          timeline.cover &&
          isMainTrack &&
          timeline.cover.clip?.id === originalClip.id &&
          (timeline.cover.assetTimestamp || 0) >= assetPosition
            ? { ...toJS(timeline.cover), clip: newClipId as any }
            : null

        timeline.tracks.forEach((track) => {
          track.clips.forEach((c, index, clips) => {
            if (c.id === originalClip.id) {
              let newClip: Clip | undefined

              if (isMainTrack && isVideo) {
                const assetOptions = originalClip.assetOptions as VideoAssetOptions
                newClip = ClipModel.create({
                  id: newClipId,
                  asset: asset.id as any,
                  assetOptions: {
                    type: AssetType.Video,
                    trimEnd: assetOptions.trimEnd,
                    trimStart: assetPosition,
                    recordedInApp: assetOptions.recordedInApp,
                    scriptId: assetOptions.scriptId,
                  },
                })
                assetOptions.setEndTrim(asset.duration - assetPosition)

                if (newCover) {
                  self.setCover(timeline, newCover)
                }
              } else if (isAudio) {
                const assetOptions = originalClip.assetOptions as AudioAssetOptions
                newClip = ClipModel.create({
                  id: newClipId,
                  asset: asset.id as any,
                  assetOptions: {
                    type: AssetType.Audio,
                    trimEnd: assetOptions.trimEnd,
                    trimStart: assetPosition,
                  },
                  _startPosition: seek,
                })
                assetOptions.setEndTrim(asset.duration - assetPosition)
              } else if (isImage) {
                newClip = ClipModel.create({
                  id: newClipId,
                  asset: asset.id as any,
                  assetOptions: { type: AssetType.Image },
                  top: originalClip.top,
                  left: originalClip.left,
                  width: originalClip.width,
                  height: originalClip.height,
                  rotation: originalClip.rotation,
                  _startPosition: originalClip.startPosition + assetPosition,
                  _duration: originalClip.duration - assetPosition,
                })

                originalClip.setDuration(assetPosition)
              } else if (isText) {
                const assetOptions = originalClip.assetOptions as TextAssetOptions
                newClip = ClipModel.create({
                  id: newClipId,
                  asset: asset.id as any,
                  assetOptions: {
                    type: AssetType.Text,
                    text: assetOptions.text,
                    color: assetOptions.color,
                    backgroundColor: assetOptions.backgroundColor,
                  },
                  top: originalClip.top,
                  left: originalClip.left,
                  width: originalClip.width,
                  height: originalClip.height,
                  _startPosition: originalClip.startPosition + assetPosition,
                  _duration: originalClip.duration - assetPosition,
                })

                originalClip.setDuration(assetPosition)
              }

              if (newClip) {
                clips.splice(index + 1, 0, newClip)
                self.selectedClip = newClip
              }
            }
          })
        })
        timeline.markAsDirty()
      }),
      removeClip(timeline: Timeline, clip: Clip) {
        const deleteTrackIfEmpty =
          timeline.isClipOnOverlayTrack(clip) || timeline.isClipOnAudioTrack(clip)
        const track = timeline.getClipTrack(clip)

        const isLastClipOnTrack = track?.clips.length === 1
        track?.clips.replace(track.clips.filter((c) => c.id !== clip.id))

        if (isLastClipOnTrack && deleteTrackIfEmpty) {
          destroy(track)
        }

        timeline.markAsDirty()
      },

      fetchTimelineByPitch: flow(function* (pitchId: string) {
        const timelineApi = new TimelineApi(self.environment.api)
        const result = yield* toGenerator(timelineApi.getTimelineByPitch(pitchId))
        self.updateLocalTimelineFromServer(result.timeline)
        return self.timelines.get(result.timeline.id)
      }),

      /**
       * Fetches any assets that are missing from the timeline
       */
      fetchMissingAssets: flow(function* (timeline: Timeline) {
        if (timeline.sync.status !== SyncStatus.Synced) {
          throw new Error("Cannot save assets until timeline is saved")
        }

        // delete any assets that aren't currently on the timeline
        // we wait to do this until now so that we don't remove anything from the originalTimeline
        // which we may want back if we discard changes
        const deletedAssetIds = difference(
          Array.from(timeline.assetSyncs.keys()),
          Object.keys(timeline.allAssets),
        )
        deletedAssetIds.forEach((deletedAssetId) => {
          timeline.assetSyncs.delete(deletedAssetId)

          // every asset has been copied to the timeline's local dir, so we can delete it if
          // it's not used
          self.timelineAssetStore.removeLocalAsset(deletedAssetId, timeline)

          // but that asset may also be used in other timelines (especially for copies) so we can
          // only remove the reference if its totally unused
          for (const t of self.timelines.values()) {
            if (t.allAssets[deletedAssetId]) {
              return
            }
          }

          self.timelineAssetStore.removeAssetReference(deletedAssetId)
        })

        if (
          Array.from(timeline.assetSyncs.values()).every(
            (assetSync) => assetSync.status === SyncStatus.Synced,
          )
        ) {
          return undefined
        }

        // check with the server to see which assets are still missing
        const api = new TimelineApi(self.environment.api)
        const result = yield* toGenerator(api.getMissingAssetUploadUrls(timeline.id))

        // this method is split from saveAssets in order to better track status, because we
        // were previously struggling to account for the period of time in between receiving the
        // asset upload urls and actually starting to upload them
        return result.uploadPostParams
      }),

      /**
       * Save the timeline's local assets directly to S3.
       */
      saveAssets: flow(function* (
        timeline: Timeline,
        preSignedPostParams: MissingTimelineAssetPostParams,
      ) {
        const promises: Promise<void>[] = []

        // check that each asset in the timeline has been uploaded
        for (const asset of Object.values(timeline.allAssets)) {
          const assetSync = timeline.assetSyncs.get(asset.id)
          // the server doesn't have the asset yet...
          if (preSignedPostParams[asset.id]) {
            if (!assetSync) {
              throw new Error("Asset sync is missing for unsynced asset")
            } else if (assetSync.status === SyncStatus.Synced) {
              // but the client thinks it does...
              throw new Error("Client incorrectly believes upload is synced")
            } else {
              if (assetSync.syncing) {
                // the upload may be in progress, but the server doesn't have any way of knowing that,
                // so reset the sync and try it again
                assetSync.setStatus(SyncStatus.New)
                // this should only happen when the save is interrupted
                logger.logError(new Error("Asset is already being synced..."))
              }

              // start the upload
              promises.push(
                assetSync.run(async () => {
                  const contentUri = asset.contentUri
                  if (!contentUri) {
                    throw new Error(`Asset ${asset.contentUri} is missing content uri`)
                  }
                  return self.environment.api.upload(
                    contentUri,
                    preSignedPostParams[asset.id].url,
                    {
                      sync: assetSync,
                      timeout: 60000,
                      postParams: preSignedPostParams[asset.id].fields,
                    },
                  )
                }),
              )
            }
          }
          // the server thinks the asset is synced but the client doesn't
          else {
            if (!assetSync) {
              const newAssetSync = cast<Sync>({ status: SyncStatus.Synced })
              timeline.assetSyncs.set(asset.id, newAssetSync)
            } else if (assetSync.status !== SyncStatus.Synced) {
              assetSync.setStatus(SyncStatus.Synced)
            }
          }
        }

        // wait for uploads to complete
        yield Promise.all(promises)

        return undefined
      }),
    }
  })
  .views((self) => ({
    get uniqueAssets() {
      return uniqBy(
        flatten(Array.from(self.timelines.values()).map((t) => Object.values(t.allAssets))),
        (asset) => asset.contentUri,
      ).filter((a) => Boolean(a.contentUri))
    },
    get isReordering() {
      return Boolean(self.reorderingState?.isReordering)
    },
    hasTracksWithClips(timeline: Timeline) {
      return timeline?.tracks.some((t) => t.clips?.length > 0)
    },
  }))

export type TimelineStore = Instance<typeof TimelineStoreModel>
export const withTimelineStore = (self: IStateTreeNode) => ({
  views: {
    get timelineStore() {
      let parent = getParent(self)
      while (parent && !(parent as any).timelineStore) {
        parent = getParent(parent)
      }
      return (parent as any).timelineStore as TimelineStore
    },
  },
})
